import {getStorage} from '@/storage'
export const mixinTable = {
  data() {
    return {
      token: getStorage('token'),
      multipleSelection: [], // 列表批量选择的数据
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  created() {
    console.log(this.token)
  },
  methods: {
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.multipleSelection = [];
      this.getTable();
    },
    // 重置查询条件每页20条数据
    reset2() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 20;
      this.multipleSelection = [];
      this.getTable();
    },
    // 查询时默认第一页
    onSearch() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.data = [];
      this.getTable();
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.table.params.count = pageSize;
      this.table.params.page = 1;
      this.getTable();
    },
    //页面改变时触发
    handleCurrentChange(page) {
      this.table.params.page = page;
      this.getTable();
    },
    // 批量选择
    handleSelectionChange(batchData) {
      this.multipleSelection = batchData;
    },
    // 字段转义
    toStr(data, key) {
      return data[key]
    },
    // el-select搜索时，去除空格，限制el-select输入或复制内容
    trimInput() {
      this.$refs.elementSelect.$data.selectedLabel = this.$refs.elementSelect.$data.selectedLabel.trim();
    },

    // 查询条件选择下单分类事件
    changeOrderCateId(value) {
      if(value.length == 1) { //一级分类
        this.table.params.order_cate_id = value[0];
        this.table.params.order_cate_tow = '';
      } else if(value.length == 2) { // 二级分类
        this.table.params.order_cate_id = value[0];
        this.table.params.order_cate_tow = value[1];
      } else { // 没选分类
        this.table.params.order_cate_id = '';
        this.table.params.order_cate_tow = '';
      }
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },

    // 签约 删除
    setConfirm(str, data, url) {
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        this.$http.post(url, data).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.getTable();
          }
        })   
      }).catch(() => {});
    },
    // js保留小数,num:金额总数，decimal：需保留的小数位数，point_rule：(1:四舍五入，2：直接舍去)
    formatDecimal(num, decimal, point_rule) {
      num = num.toString()
      let index = num.indexOf('.')
      if(point_rule == 2) { // 保留小数后直接舍去
        if (index !== -1 ) {
          num = num.substring(0, decimal + index + 1)
        } else {
          num = num.substring(0)
        }
        console.log(parseFloat(Number(num).toFixed(decimal)),"a1")
        return parseFloat(Number(num).toFixed(decimal)); // 避免自动补0
      } else { // 保留小数后四舍五入
        console.log(parseFloat(Number(num).toFixed(decimal)),"a2")
        return parseFloat(Number(num).toFixed(decimal)); // 避免自动补0
      }
    },
    // 全部导出
    exportData(url) {
      let str = ""
      let obj = {
        ...this.table.params
      }
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 批量导出
    plexportData(url) {
      if (this.multipleSelection.length) {
        let ids = this.multipleSelection
          .map((item) => {
              return item.id;
          }).join(",");
        let str = ""
        let obj = {
          ...this.table.params,
          ids
        }
        Object.keys(obj).forEach(item => {
            str += `&${item}=${obj[item]}`
        })
        console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    // 获取商品分类
    suggestionsCateFn(value, cb){
      this.$http.get('/admin/cate/all', {params:{page:1,size:1000}}).then(res => {
        if(res.code == 1) {
          let arr = res.data.map(item => {
            return { cate_id: item.id, value: item.name };
          });
          arr = value ? arr.filter(this.createFilter(value)) : arr   // 过滤
          if (arr.length) {
            cb(arr);
          } else {
            this.form.data.cate_name = "";
            cb([{ value: "无数据", cate_id: "" }]);
          }
        }
      })
    },
    createFilter(value) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      };
    },

    // 添加订单、采购单、盘点单，列表输入商品名称查询商品
    remoteMethod(query) {
      if(query !== '') {
        this.selLoading = true;
        this.$http.get('/admin/goods/specList', { params:{ page: 1, count: 1000, status:1, good_name: query }}).then(res => {
          if(res.code === 1) {
            this.goodArr = res.data.list;
          }
        }).finally(() => {
          this.selLoading = false;
        })
      } else {
        this.goodArr = [];
      }
    },

    // 列表查询条件获取客户列表
    getMerchantSel() {
      // type 1:列表 2:参数
      this.$http.get("/admin/group/merchantList", {params:{type:1,page:1,count:10000}}).then((res) => {
        if(res.code == 1) {
          this.merchantArr = res.data.list;
        }
      })
    },
    // 列表查询条件客户的食堂列表
    getStoreSel() {
      this.$http.get('/admin/path/storeList', {params: {page:1,count:10000,merchant_id:this.table.params.merchant_id}}).then(res => {
        if(res.code === 1) {
          this.storeArr = res.data.list;
        }
      })
    },
    // 选择客户查询后先清空食堂
    changeMerchant() {
      this.table.params.address_id = '';
      this.onSearch(); // 查询列表
    },

    // 列表查询条件供应商/采购员列表
    getSupplierSel() {
      this.$http.get('/admin/supplier/allList',{params:{ page:1, count:10000}}).then(res => {
        if(res.code == 1) {
          this.supplierArr = res.data.list;
        }
      })
    },
    // 下单分类 
    getOrderCateSel() {
       // 下单分类旧接口（/admin/goods/orderCate）
      this.$http.get('/admin/mini_cate/list', {params:{page:1,count:1000}}).then(res => {
        if(res.code === 1) {
          this.orderCateArr = res.data.list.map(obj => ({ ...obj })).reduce((acc, obj) => {
            if (obj.child.length == 0) {
              delete obj.child; // 删掉空的二级分类child
              acc.push(obj);
            } else {
              acc.push(obj);
            }
            return acc;
          }, []);
        }
      })
    },
  }
}